import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle"]

  connect() {
    console.log("Theme controller connected");
    this.updateTheme();
  }

  toggle() {
    console.log("Toggle clicked");
    if (this.toggleTarget.checked) {
      localStorage.theme = 'dark'
    } else {
      localStorage.theme = 'light'
    }
    this.updateTheme();
  }

  updateTheme() {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark')
      this.toggleTarget.checked = true
    } else {
      document.documentElement.classList.remove('dark')
      this.toggleTarget.checked = false
    }
  }
}
